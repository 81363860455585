import React from 'react';

export const MySite = () => {
    return (
        <div>
        <div class="middle">
          <h1>COMING SOON</h1>
          <hr />
          <p>35 days left</p>
        </div>
        <div class="bottomleft">
          <p>Some text</p>
        </div>
      </div>
    )
}