export const API_BE = "https://duksiapi.com/api";
export const BLOCK_ORG = [
    'Ipko',
    'Kosoves',
    'Artmotion',
    'Amazon',
    'Kujtesa',
    'Telkos',
    'Microsoft',
    'Trustwave',
    'Google',
    'Cloudflare',
    'Leaseweb',
    'Facebook',
    'Aspect',
    'Astute',
    'Nuance',
    'Creative Virtual',
    'IBM',
    'RASA',
    'CogniCor',
    'Inbenta',
    'Avaamo',
    'Nuance',
    'Apple'
]
